<form role="form" [ngStyle]="{'background-image':'url(../assets/img/Platform_Cube_Bg-WebLarge.png)','height':ScreenHeight+'px','background-repeat': 'round'}" class="mb-lg"  (ngSubmit)="submitForm(user)">
	<div *ngIf="loadershow" class="loader text-center">
		<img src="../assets/img/cc_loader.gif" />
	</div>
	<div class="container container-sm animated fadeInDown">
		<div class="center-block mt100">
			<div style="margin-left: 10%;background-color: #ffffffb3;" class="col-sm-9">
				<div class="text-center mt100">
					<img src="../assets/img/smrheader.jpg" alt="Image" width="130px"/>
				</div>
				<fieldset class="mt20">
					<div class="col-sm-12 form-group">
						<div>
							<div style="box-shadow: 2px 2px 2px #a69e9e;" class="input-group m-b">
								<span class="input-group-addon labelColor">Email-ID &nbsp;&nbsp;</span>
								<input type="email" id="user_email" placeholder="Email-ID" name="email" id="email" class="form-control input_focus input-xs" [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}" required/>
							</div>
							<br/>
						</div>
					</div>
					<div class="col-sm-12 form-group">
						<div style="box-shadow: 2px 2px 2px #a69e9e;" class="input-group m-b">
							<span class="input-group-addon labelColor">Password &nbsp;</span>
							<input required id="user_password" type="password" name="password" id="password" placeholder="Password" [(ngModel)]="user.password" class="form-control input_focus input-xs" />
						</div>
						
						<div *ngIf="checkCapsLock" class="fs_15em pull-right text-warning text-bold"><br/><i class="fa fa-exclamation-triangle text-warning"></i> Caps Lock is on</div>
						<br/>
					</div>
					<div class="col-sm-12 form-group">
						<div>
							<button id="btnSubmit" type="submit" class="btn labelColor btn-oval col-sm-12">Log-In</button>
						</div>
					</div>
					<div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By<a href="http://3cubeservices.com/" target="_blank" class="text-black">&nbsp;3CUBE</a> &copy; {{year}} </div>
					<div id="linkRecover" [routerLink]="['/recover']" class="text-center text-bold text-info" style="cursor: pointer;">Forgot Password?</div>
					<div class="col-sm-12 text-center mt"><a id="linkDownloadChrome" target="_blank" href="https://www.google.com/chrome/?brand=CHBD&amp;gclid=Cj0KCQiAs67yBRC7ARIsAF49CdUGdGoXkKKSGu5dnHQLejknEU7STLQiitJ8XRY2VLm3y6wg5Sg8bc0aAk9XEALw_wcB&amp;gclsrc=aw.ds" class="btn-info btn-sm text-bold">Download Chrome</a>
					</div>
					<div class="text-center text-bold"></div>
				</fieldset>
				<div *ngIf="loginErrorshow" role="alert" type="danger" class="alert ng-isolate-scope alert-danger alert-dismissible">
					<button id="btnCloseErrorMsg" type="button" class="close">
						<span class="sr-only">Close</span>
					</button>
					<div class="text-center">
						<span class="ng-binding ng-scope">{{loginFormErr}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
