<form role="form" [ngStyle]="{'background-image':'url(../assets/img/Platform_Cube_Bg-WebLarge.png)','height':ScreenHeight+'px','background-repeat': 'round'}" class="mb-lg" [formGroup]="recoverForm" #recform="ngForm" (ngSubmit)="submitForm()">
	<div class="container container-sm animated fadeInDown">
		<div class="center-block mt100">
			<div style="margin-left: 10%;background-color:white;" class="col-sm-8">
				<h2 class="text-center" style="margin-top: 20px;">Reset your password</h2>
				<div style="line-height: 2;" class="mt-lg fs_14 text-center">Please choose a new password</div>
				<div class="mt20">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group has-feedback">
								<input placeholder="Password" id="password" name="password" type="password" formControlName="password" class="form-control" autocomplete="off"/>
								<span class="fa fa-lock form-control-feedback text-muted"></span>
								<small *ngIf="f.password.invalid && (recform.submitted || f.password.touched || f.password.dirty)" style="color: maroon;">
									<span *ngIf="f.password.errors.required">
										Please enter something.
									</span>
									<div [ngClass]="f.password.errors.minlengtherror?'ErrorColor':'Suucesscolor'">
										Password Must have 8 characters long.
									</div>
									<div [ngClass]="f.password.errors.capitalerror?'ErrorColor':'Suucesscolor'">
										Password Must Have 1 Capital letter
									</div>
									<div [ngClass]="f.password.errors.numericerror?'ErrorColor':'Suucesscolor'">
										Password must have 1 Numeric value
									</div>
								</small>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group has-feedback">
								<input placeholder="Confirm Password" id="confirmPassword" name="confirmPassword" type="password" formControlName="confirmPassword" class="input-filter form-control" autocomplete="off"/>
								<span class="fa fa-lock form-control-feedback text-muted"></span>
								<small *ngIf="f.confirmPassword.invalid && (recform.submitted || f.confirmPassword.touched || f.confirmPassword.dirty)" style="color: maroon;">
									<span *ngIf="f.confirmPassword.errors.required">
										Please enter something.
									</span>
								</small>
								<small *ngIf="recform.errors && (recform.submitted || f.confirmPassword.touched || f.confirmPassword.dirty)">
									<p *ngIf="recform.errors.passwordNotMatch" style="color:maroon" role="alert">Password and Confirm Password not matched!<i style="font-size: 22px;color: red;" class="fa fa-times faa-ring animated"></i></p>
								</small>
							</div>
						</div>
						<div class="col-md-4 col-md-offset-4">
							<button id="btnSubmit" [disabled]="recoverForm.invalid || loadershow" type="submit" class="btn btn-danger btn-block"><i *ngIf="loadershow" aria-hidden="true" class="fa fa-spinner fa-spin"></i> Send</button>
						</div>
					</div>
				</div>
				<div *ngIf="resetPasswordError" class="col-sm-12">
					<div class="text-center">
						<h5 class="text-danger">{{resetPasswordError}}</h5>
					</div>
				</div>
				<div class="col-sm-12 text-center text-black text-bold mt-lg">Powered By 3CUBE © {{year}}</div>
			</div>
		</div>
	</div>
</form>
