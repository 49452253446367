import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Injectable({
	providedIn: 'root'
})
export class MessagingService {
	currentMessage = new BehaviorSubject(null);
	public chatTable: AngularFirestoreCollection<any>;
	public chats: Observable<any[]>;
	chatLength = null;
	test: any;
	private readonly worker: Worker;
	private onMessage = new Subject<MessageEvent>();
	private onError = new Subject<ErrorEvent>();
	private newMsg = new Subject<any>();

	constructor(
		private angularFireMessaging: AngularFireMessaging,
		public afAuth: AngularFireAuth,
		private firestore: AngularFirestore,

		) {
		this.angularFireMessaging.messages
		.subscribe((message) => {
			this.currentMessage.next(message);
		});

		navigator.serviceWorker.addEventListener('message', (event) => {
			var messageFrom = event.data.senderId;
			let isFocus =  window.document.hasFocus();
			if(!isFocus){
				let param = {id : messageFrom};
			}

		});
		self.addEventListener('message', event => {
			var messageFrom = event.data.senderId;
			let isFocus =  window.document.hasFocus();
			if(!isFocus){
				let param = {id : messageFrom};
				this.callMe(messageFrom);
			}
			this.log("ACTIVATING");
		});
		this.angularFireMessaging.onMessage((payload) => {
			this.newMsg.next();
		});
	}
	
	public getNewMsgEvent(): Observable<any>{ 
		return this.newMsg.asObservable();
	}
	

	log(message) {
	}

	callMe(id){
	}

	requestPermission(): Observable<any> {
		var token = this.angularFireMessaging.requestToken;
		this.sendTokenToServer(token);
		return token;
	}

	async register(email: string, password: string) {
		try {
			const response = await this.afAuth.createUserWithEmailAndPassword(email, password);
			return response;
		}
		catch (e) {
			if (e.code === 'auth/email-already-in-use') {
				var signData = await this.afAuth.signInWithEmailAndPassword(email, password);
				return signData;
			}
		}
	}

	async checkUserData(email: string) {

		try {
			const res = await this.firestore.collection('users').ref.where('email', '==', email)
			.get();
			return res.docs.length === 0 ? false : true;
		}
		catch (err) {
		}

	}

	async checkUserData1(email) {
		let uId_string = email.toString();
		try {
			const res = await this.firestore.collection('users').doc(uId_string)
			.get();
			return res;
		}
		catch (err) {
		}

	}

	updateDataToDb(deviceId: any, uId: any) {
		let uId_string = uId.toString();
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("users")
			.doc(uId_string)
			.set({ status: 'online', deviceId: deviceId }, { merge: true })
			.then(res => { this.updateUserStatus(uId_string,"online"); resolve(true) }, err => reject(false));
		});
		return promise;
	}

	updateUserStatus(uId,status){
		let uId_string = uId.toString();
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("doctors")
			.doc(uId_string)
			.update({ status: status })
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}

	userSignOut(){
		var promise = new Promise<any>((resolve, reject) => {
			this.afAuth.signOut()
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}

	updateMsgStatus(uId: any) {

		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("doctors")
			.doc(uId)
			.set({ msgStatus: false }, { merge: true })
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};

	addMsgCount(uId: any) {
		let uId_string = uId.toString();
		let docData = {
			count: 0
		};

		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("msgcount")
			.doc(uId_string)
			.set(docData)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};

	updateMsgCount(uId: any) {
		let uId_string = uId.toString();
		let docData = {
			count: 0
		};

		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("msgcount")
			.doc(uId_string)
			.update(docData)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};

	addMsgsToMessageVessel(data, uId) {

		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("messages")
			.doc("doctors")
			.collection(uId)
			.add(data)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;

	};

	addMsgsToMessageDoctor(data, uId) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("messages")
			.doc(uId)
			.collection("doctors")
			.add(data)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};

	updateChatTimeStamp(uId, text) {

		let updateFields = {
			'timestamp': new Date(),
			'addedBy': 'vessel',
			'latestMessage': text,
			'msgStatus':true
		};

		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("doctors")
			.doc(uId)
			.set(updateFields, { merge: true })
			.then(res => { resolve(true) }, err => reject(false));
		});

		return promise;
	};

	async addUserDoctor(uId: any, deviceId: any, email: string, name: string) {

		let uId_string = uId.toString();
		let docData = {
			callStatus: "not_on_call",
			deviceId: deviceId,
			email: email,
			status: "online",
			userId: uId_string,
			name: name,
			latestMessage: "",
			msgStatus: false,
			timestamp: new Date(),
			addedBy: "vessel",
		};

		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("doctors")
			.doc(uId_string)
			.set(docData)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};

	addDataToDb(email: string, deviceId: string, uId: any, callSign: string, name: string) {

		let uId_string = uId.toString();
		let doc = {
			alternateId: email,
			deviceId: deviceId,
			email: email,
			state: 0,
			status: "online",
			type: "doctor",
			userId: uId_string,
			callSign: callSign,
			name: name,
		};

		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("users")
			.doc(uId_string)
			.set(doc)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};

	receiveMessage() {
		this.angularFireMessaging.messages.subscribe(
			(msg) => {
				this.currentMessage.next(msg);
			})
	}

	listen() {
		this.angularFireMessaging.messages
		.subscribe((message) => { });
	}

	sendTokenToServer(currentToken) {
		if (!this.isTokenSentToServer()) {
			this.setTokenSentToServer(true);
		} else {
		}
	}

	isTokenSentToServer() {
		return window.localStorage.getItem('sentToServer') == 'true';
	}

	setTokenSentToServer(sent: boolean) {
		window.localStorage.setItem('sentToServer', sent ? 'true' : 'false');
	}

	removeDoctorUserCall() {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("call")
			.doc("doctors")
			.delete()
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};

	removeVesselUserCall(uId) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("call")
			.doc(uId)
			.delete()
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};

	addVideoCallUser(data) {

		var promise = new Promise<any>((resolve, reject) => {
		  this.firestore
			.collection("participants")
			.add(data)
			.then(res => { resolve(res) }, err => reject(err));
		});
		return promise;
	
	  };
	
	  sendMessageInVideoCall(data) {
		var promise = new Promise<any>((resolve, reject) => {
		  this.firestore
			.collection("chat")
			.add(data)
			.then(res => { resolve(res) }, err => reject(err));
		});
		return promise;
	
	  };
}
