import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
@Injectable({
	providedIn: 'root'
})
export class ApiService {
	general_url = environment.API_GENERAL_URL;
	baseURL = environment.API_GENERAL_URL+"api/";
	hcAPIURL = environment.FILE_GENERAL_URL+"api/";
	private refreshMailList = new Subject<any>();

	constructor(private httpClient: HttpClient) { }

	public getGeneralUrl(){
		return this.general_url;
	}

	sendRefreshMailListEvent() {
		this.refreshMailList.next();
	}
	getRefreshMailListEvent(): Observable<any>{
		return this.refreshMailList.asObservable();
	}

	public loginAPI(user){
		var data = {email:user.email,password:user.password}
		return this.httpClient.post(this.baseURL+"login",data);
	}

	public logout(){
		var token = localStorage.getItem('token');
		var data = {token:token};
		var headers = {
			'Authorization':token,
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"logout",data,{headers:headers});
	}

	public passwordReset(email){
		var data = {email:email}
		return this.httpClient.post(this.baseURL+"passwordReset",data);
	}

	public newPassword(data){
		return this.httpClient.post(this.baseURL+"newPassword",data);
	}

	public inventoryAlert(params){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"inventoryAlert",options);
	}

	public displaylowqtyAlert(){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.get(this.baseURL+"displaylowqtyAlert",{headers:headers});
	}

	public listMailFolders(page,limit,search){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const params: any = { page: page, limit: limit, search: search};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"listMailFolders",options);
	}

	public getVesselDetails(vesselId){
		var params = {vesselId:vesselId}
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"vesselDetails",options);
	}

	public seafarerList(params){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"seafarerList",options);
	}

	public removeSeafarerDetails(seafarerId){
		var data = {seafarerId:seafarerId}
		// var headers = {
		// 	'Authorization':localStorage.getItem('token'),
		// 	'Content-Type':'application/json',
		// 	'Accept':'application/json'
		// }
		// const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		// const options = { params: new HttpParams(httpParams), headers: headers };
		// return this.httpClient.post(this.baseURL+"unlinkSeafarer",options);

		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"unlinkSeafarer",data,{headers:headers});
	}

	public rankList(){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.get(this.baseURL+"rankList",{headers:headers});
	}

	public identityDocumentList(){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.get(this.baseURL+"identityDocumentList",{headers:headers});
	}

	public nationalityList(search){
		var params = {search:search};
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"nationalityList",options);
	}

	public addSeafarerDetails(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"seafarerDetails",data,{headers:headers});
	}

	public editSeafarerDetails(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"editSeafarerDetails",data,{headers:headers});
	}

	public getSeafarerDetails(seafarerId){
		var params = {seafarerId:seafarerId};
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"seafarerDetails",options);
	}

	public getCandidateDetails(id){
		var params = {id:id}
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"candidateDetails/"+id,options);
	}

	public deviceList(params){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"deviceList",options);
	}

	public portList(search){
		var params = {search:search};
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"portList",options);
	}

	public humanAnatomyAll(){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.get(this.baseURL+"fetch_coordinates",{headers:headers});
	}

	public symptomQuestions(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"symptom_questions",data,{headers:headers});
	}

	public createCustomEvent(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"sendmail",data,{headers:headers});
	}

	public event_details(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"event_details",data,{headers:headers});
	}

	public event_reply(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"event_reply",data,{headers:headers});
	}

	public changeCaseStatus(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"changeCaseStatus",data,{headers:headers});
	}

	public getCaseId(params){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"getCaseId",options);
	}

	public getLatestMailReply(params){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"getLatestMailReply",options);
	}

	public getVideoToken(params){
		/*var headers = {
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		var data = {"sessionId": "Technicious","password":"Tech@123"};
		return this.httpClient.post("https://openvidu.technicious.in/join/",data,{headers:headers});*/

		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"video/getToken",options);
	}

	public fileUpload(file){
		const formData = new FormData()
		formData.append('file', file)
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization",localStorage.getItem('token'));
		return this.httpClient.post(this.baseURL+"fileUpload", formData, { headers: _headers })
	}

	public fetchReplyEmails(emailtext){
		var headers = {
			'Authorization':localStorage.getItem('tokenKey'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const params: any = { emailtext: emailtext};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		//return this.httpClient.get(this.baseURL+"fetchReplyEmails",options);
		return this.httpClient.get("https://hc-api.3cubehealth.com/api/fetchReplyEmails",options);
	}

	public eventSQV(unique_id){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		var params = {unique_id:unique_id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"eventSQV",options);
	}

	public saveEventQnAnswers(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"saveEventQnAnswers",data,{headers:headers});
	}

	public saveEventVitals(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"saveEventVitals",data,{headers:headers});
	}

	public vesselsMedicine(params){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"vesselsMedicine",options);
	}

	public fetchLocationList(params){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"fetchLocationList",options);
	}

	public AddMedicineBatch(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"AddMedicineBatch",data,{headers:headers});
	}

	public DisplayBatchList(params){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"DisplayBatchList",options);
	}

	public updateMedicine(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"addPrescription",data,{headers:headers});
	}

	public removePOC(id){

		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.delete(this.baseURL+"pointOfContact/"+id,{headers:headers});
	}

	public pointofcontactList(params){
		var headers = {
		  'Authorization':localStorage.getItem('token'),
		  'Content-Type':'application/json',
		  'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"pointOfContact",options);
	  }

	  public getPOCDetails(id){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const httpParams: HttpParamsOptions = { fromObject: id } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"pointOfContact/"+id,options);
	}

	public addPOC(data){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.post(this.baseURL+"pointOfContact",data,{headers:headers});
	}

	public updatePOC(data,id){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		return this.httpClient.put(this.baseURL+"pointOfContact/"+id,data,{headers:headers});
	}

	public fetchCCEmails(){
		var headers = {
			'Authorization':localStorage.getItem('token'),
			'Content-Type':'application/json',
			'Accept':'application/json'
		}
		const options = {  headers: headers };
		return this.httpClient.get(this.baseURL+"fetchCCEmails",options);
	}

	public getAgoraToken(channelName){
		const formData = new FormData();
		formData.append('channelName', channelName);
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization",localStorage.getItem('token'));
		return this.httpClient.post(this.baseURL+"agora/token", formData, { headers: _headers });
	}

	public getAgoraTokenOpen(channelName){
		const formData = new FormData();
		formData.append('channelName', channelName);
		return this.httpClient.post(this.baseURL+"agora/openToken", formData);
	}

}

export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}
