import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	recoverForm: FormGroup;
	year: number = new Date().getFullYear();
	ScreenHeight: number = undefined;
	loadershow: boolean = false;
	forgotDiv=true;
	resetDiv=false;
	resetPasswordError=null;
	email=null;
	token=null;
	constructor(
		private apiService: ApiService,
		private router: Router,
		private formBuilder: FormBuilder,
		private activeRoute: ActivatedRoute,
		private toastr: ToastrService,
		) {
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.email = routeParams["email"] == undefined ? null : routeParams["email"];
			this.token = routeParams["token"] == undefined ? null : routeParams["token"];
			this.recoverForm = this.formBuilder.group({
				password: ['', [Validators.required,this.passwordMinLengthValidator(8),this.passwordCapitalValidator(),this.passwordNumericValidator()]],
				confirmPassword: ['', [Validators.required]],
			}, { 
				validators: this.passwordMatchValidator.bind(this)
			});
			setTimeout(() => {
				this.ScreenHeight=(window.innerHeight);
			}, 0);
		});
	}

	get f() { return this.recoverForm.controls; }

	passwordMatchValidator(formGroup: FormGroup) {
		const { value: password } = formGroup.get('password');
		const { value: confirmPassword } = formGroup.get('confirmPassword');
		return password === confirmPassword ? null : { passwordNotMatch: true };
	}

	passwordMinLengthValidator(min: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if(password==null){
				return null;
			}
			if(password.length >= min){
				return null;
			}else{
				return {minlengtherror:true};
			}
		};
	}

	passwordCapitalValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if(password==null){
				return null;
			}
			if(password.replace(/[^A-Z]/g, "").length >= 1){
				return null;
			}else{
				return {capitalerror:true};
			}
		};
	}

	passwordNumericValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if(password==null){
				return null;
			}
			if(password.replace(/[^0-9]/g, "").length >= 1){
				return null;
			}else{
				return {numericerror:true};
			}
		};
	}

	submitForm() {
		if(this.recoverForm.invalid){
			return;
		}else{}
		var params = {
			email: this.email,
			password: this.f.password.value,
			password_confirmation: this.f.confirmPassword.value,
			token: this.token
		}
		this.loadershow = true;
		this.apiService.newPassword(params).subscribe(
			data => {
				this.loadershow = false;
				this.toastr.success(JSON.stringify(data), 'Success',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
				setTimeout(() => {
					this.router.navigate(['/login']);
				}, 3000);
			},
			(error)=>{
				this.loadershow = false;
				this.resetPasswordError=error["error"];
				setTimeout(() => {
					this.resetPasswordError=null;
				}, 10000);
			}
			);
	}
}
