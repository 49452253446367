import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RefreshComponent } from './refresh/refresh.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {  MatSelectModule } from '@angular/material/select';
import {  MatFormFieldModule } from '@angular/material/form-field';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import { ToastrModule } from 'ngx-toastr';
import {MatDialogModule} from '@angular/material/dialog';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';

import {MatChipsModule} from '@angular/material/chips';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
//import { InPlaceEditorModule } from '@syncfusion/ej2-angular-inplace-editor';
//import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RecoverComponent } from './recover/recover.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UrlSerializerService } from './url-serializer.service';
import {UrlSegment, UrlSerializer} from '@angular/router';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
//import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MessagingService } from './firebase/messaging.service';
//import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedHttpService } from './authenticated-http.service';
// import { OpenviduSessionModule } from 'openvidu-angular';
import * as moment from 'moment';
// import { VideoCallComponent } from './video-call/video-call.component';
// import { VideoComponent } from './video/video.component';
import {  SafePipe }   from './shared/pipes/safe.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { SharedPipesModule } from './shared-pipes.module';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		
		RefreshComponent,
		RecoverComponent,
		ResetPasswordComponent,
		// VideoCallComponent,
		// VideoComponent,
		SafePipe,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgSelectModule,
		FormsModule,
		
		BrowserAnimationsModule,
		
		MatSelectModule,
		MatFormFieldModule,
		
		MatAutocompleteModule,
		MatInputModule,
		MatRadioModule,
		MatDialogModule,
		ToastrModule.forRoot(),
		NgbModule,
		TagInputModule,
		RichTextEditorAllModule,
		MatChipsModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatNativeDateModule,
		//InPlaceEditorModule,
		//AutoCompleteModule,
		NgxFileDropModule,
		// AngularFireDatabaseModule,
		// AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		// OpenviduSessionModule,
		MatTableModule,
		MatListModule,
		SharedPipesModule
	],
	providers: [
		{
			provide: UrlSerializer,
			useClass: UrlSerializerService
		},
		MessagingService,
		AsyncPipe,
		{
			provide: HTTP_INTERCEPTORS, 
			useClass: AuthenticatedHttpService, 
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
