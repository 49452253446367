import { Component, OnInit, NgZone } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	user: any = { email: '', password: '' };
	year: number = new Date().getFullYear();
	ScreenHeight: number = undefined;
	loadershow: boolean = false;
	loginErrorshow: boolean = false;
	checkCapsLock: boolean = false;
	loginFormErr: string = "";
	
	constructor(private apiService: ApiService,private router: Router,private zone: NgZone) {}
	
	ngOnInit(): void {
		if(localStorage.getItem('token') != null && localStorage.getItem('token') != undefined){
			this.router.navigate(['/app/listcrew']);
		}
		if(localStorage.getItem('sessionexpire') == "true"){
			this.loginErrorshow = true;
			this.loginFormErr = "Your session has been expired.";
			setTimeout(() => {
				this.loginErrorshow = false;
				localStorage.removeItem('sessionexpire')
			}, 5000);
		}
		
		setTimeout(() => {
			this.ScreenHeight=(window.innerHeight);
		}, 0);
		var emailInput = document.getElementById("email");
		var passwordInput = document.getElementById("password");
		emailInput.addEventListener("keyup", (event)=> {
			if (event.getModifierState("CapsLock")) {
				this.checkCapsLock = true;
			} else {
				this.checkCapsLock = false;
			}
		});
		passwordInput.addEventListener("keyup", (event)=> {
			if (event.getModifierState("CapsLock")) {
				this.checkCapsLock = true;
			} else {
				this.checkCapsLock = false;
			}
		});
	}
	
	submitForm(user) {
		this.loadershow = true;
		this.apiService.loginAPI(user).subscribe(data => {
			localStorage.setItem('token', "Bearer "+data["success"]["token"]);
			localStorage.setItem('OwnerId', data["user_id"]);
			let userLogype='';
			(data["vesselData"]) ?  userLogype = 'vessel' : userLogype = 'comp';
			if(userLogype==='vessel'){
				var vessDet = data["vesselData"];
				vessDet["Flagname"] = data["flagData"]["flagstate"]
				localStorage.setItem("vesselInfo", JSON.stringify(vessDet));
				localStorage.setItem("ActiveClinic",data["clinicId"]);
				localStorage.setItem("chatMessages", "true");
				localStorage.setItem("userEmail", user.email);
			}else {
				localStorage.setItem("companyInfo", JSON.stringify(data["companyData"]));
			}
			localStorage.setItem('fireString', data["fireString"]);
			if(userLogype==='vessel'){
				this.router.navigate(['/app/listcrew']);
			}else {
			}
		},(error)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Email-Id Or Password is incorrect.";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		}
		);
	}
}
