export const environment = {
	production: false,
	API_GENERAL_URL: 'https://smr-api.3cubehealth.com/',
	FILE_GENERAL_URL: 'https://hc-api.3cubehealth.com/',
	fileBaseUrl: 'https://smr-ng.3cubehealth.com/assets/files',
	firebaseConfig : {
		apiKey: "AIzaSyB8nPDxTJGmW6YMzwvGdtUcFZwg25Xeba0",
		authDomain: "videocall-staging.firebaseapp.com",
		projectId: "videocall-staging",
		storageBucket: "videocall-staging.appspot.com",
		messagingSenderId: "251343964324",
		appId: "1:251343964324:web:339ea2743c66a8f3f0be31"
	},
	firebaseLogin:{
		username:"dummyfirebase@test.com",
		password:"FirePass@88"
	},
	firebaseJSFile:"firebase-messaging-sw-staging.js",
	pusherConfig : {
		PUSHER_API_KEY: '1e5e47f4621a5073264a',
		PUSHER_API_CLUSTER: 'ap2'
	},
	agoraAppID:'9a24bddef79e47d08b778543fbbafa75'
};